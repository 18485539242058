<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col v-if="isAllowed('supplier','c')" cols="4">
        <v-container>
          <v-row>
            <v-col class='d-flex flex-row align-center'>
              <h1>Add New {{this.singular}}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Company Name"
                  outlined
                  v-model="data.name"
              />
              <v-text-field
                  label="Contact Name"
                  outlined
                  v-model="data.contactName"
              />
              <v-text-field
                  label="Phone"
                  outlined
                  v-model="data.phone"
              />
              <v-text-field
                  label="Address"
                  outlined
                  v-model="data.address"
              />
              <v-text-field
                  label="Email"
                  outlined
                  v-model="data.email"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="create" :disabled="!this.data.name">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.plural}}</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-text-field
                      v-model="options.search"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
                  <v-btn color="info" fab x-small><v-icon>mdi-magnify</v-icon></v-btn>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="allData"
                    :options.sync="options"
                    :server-items-length="pagination.dataCount"
                    :loading="pagination.loading"
                    :items-per-page="-1"
                    class="elevation-1"
                    :footer-props="{
                      itemsPerPageOptions: pagination.pageSizeOptions
                    }"
                    @click:row="rowClick"
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog scrollable v-model="existingDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Confirm Create
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <p>Similar {{this.plural}} already exist. Are you sure you want to create another?</p>
          <br>
          <h3>Similar {{this.plural}}</h3>
          <span v-for="(item, i) in existingData" :key="i">{{i!==0?" | ":""}}<b>{{item.name}}</b>(ID:{{item.id}})</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" text @click="confirmCreate">
            Create
            <v-progress-circular
                indeterminate
                color="green"
                v-if="dialogLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
          <v-btn class="error" text @click="closeDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Contact Name', value: 'contactName' },
          { text: 'Phone', value: 'phone' },
          { text: 'Address', value: 'address' },
          { text: 'Email', value: 'email' },
        ],
        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          search: "",
          sortBy: ['name'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15
        },
        updatingRoute: false,
        init: false,

        editMode: false,

        existingData: [],
        existingDialog: false,
        dialogLoading: false,

        data: {},
        allData: [],
        singular: "Supplier",
        singularLower: "supplier",
        plural: "Suppliers",
        pluralLower: "suppliers"
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "name"]
        this.options.sortDesc = [oldQuery.order || true]
        this.options.search = oldQuery.search || ""
        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
      this.init = true;

      this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

      await this.getAllData();
      this.init = false;
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'getGlobalValue'])
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "name"]
          this.options.sortDesc = [oldQuery.order || true]
          this.options.search = oldQuery.search || ""
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      //used for pagination
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        return oldQuery;
      },
      //used for pagination end
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async create(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.data)
          if(res.data.error) throw res.data.error

          if(res.data.data.created){
            this.snack(`${this.singular} Created 🎉`, "success");
            this.data={};
            await this.getAllData();
          }
          else{
            this.existingDialog = true;
            this.existingData = res.data.data.data;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async confirmCreate(){
        try {
          this.dialogLoading = true;
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/confirmCreate`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Created 🎉`, "success");
          this.data={};
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.closeDialog();
        }
      },
      closeDialog(){
        this.existingDialog = false;
        this.existingData = [];
        this.dialogLoading = false;
      },
      async getAllData(){
        try {
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            search: this.options.search
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.allData = res.data.data

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};
          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;

          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;
              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }


          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.replace({path: `/${this.pluralLower}`, query: newQuery});
            }
            else{
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.push({path: `/${this.pluralLower}`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end

        } catch (error) {
          console.error(error);
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      }
    }
  }
</script>
